import React, { useState } from "react";
// import './NftList.css';

import { useEffect } from "react";

interface ContainerProps {
  address: string | null;
  collection: string | null;
  addNftToSelected: any;
}

const NftList: React.FC<ContainerProps> = ({
  address,
  collection,
  addNftToSelected,
}) => {
  const [nfts, setNfts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  function addAll() {
    nfts.map((n) => (
      addNftToSelected(n)
    ));
  }

  function addToSelectedNfts(nft: []) {
    addNftToSelected(nft);
  }

  useEffect(() => {
    function loadNfts() {
      setLoading(true);

      if (collection && address) {
        const url =
          "https://jck-cors-proxy.onrender.com/https://api.niftyreel.com/"+address
          +"/"+collection
          +"/assets" +
          "";

        fetch(url)
          .then((res) => res.json())
          .then(
            (result) => {
              setLoading(false);
              setError(false);
              setNfts(result.assets);
            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
              console.log("error", error);
              setLoading(false);
              setError(true);
            }
          );
      }
    }

    loadNfts();
  }, [address, collection]);

  return (
    <div className="flex flex-col overflow-hidden p-2 w-46">
      {nfts.length > 0 ? 
        <div className="flex items-center bg-indigo-200 dark:bg-indigo-900 p-2 font-bold rounded-t-lg">
          <h2 className="">Nfts</h2>
          <button onClick={(event) => addAll()} className="ml-auto text-sm">Add all</button>
        </div>
      : '' }

      <div className="overflow-y-auto">

      {collection && loading ? <p>Loading...</p> : ""}
      <div className="flex flex-col overflow-auto">
        {nfts
          ? nfts.map((n) => (
            <div className="w-40 p-2 border-b dark:border-gray-400 dark:bg-black" key={n["id"]}>
              <button onClick={(event) => addToSelectedNfts(n)} className="relative">
              <img
                className=""
                src={n["image_url"]}
                alt=""
              />

<svg xmlns="http://www.w3.org/2000/svg" className="absolute top-0 right-0 h-5 w-5 bg-white mt-1 mr-1" viewBox="0 0 20 20" fill="currentColor">
  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clipRule="evenodd" />
</svg>              
              </button>
            </div>
            ))
          : {}}
      </div>

      </div>
    </div>






  );
};

export default NftList;
