import React, { useState } from "react";
// import './ExploreContainer.css';

import { useEffect } from "react";

interface ContainerProps {
  address: string | null;
  setCollection: any;
}

const CollectionList: React.FC<ContainerProps> = ({
  address,
  setCollection,
}) => {
  const [collections, setCollections] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedCollection, setSelectedCollection] = useState(null);
  const [error, setError] = useState(false);

  //

  function updateSelectedCollection(col: any) {
    setSelectedCollection(col);
    setCollection(col);
  }

  useEffect(() => {
    function loadCollections() {
      setLoading(true);

      const url =
        "https://api.opensea.io/api/v1/collections?format=json&asset_owner=" +
        address +
        "&offset=0&limit=100";

      fetch(url)
        .then((res) => {
          if (!res.ok) {
            throw res;
          }
          return res.json();
        })
        .then(
          (result) => {
            setLoading(false);
            setError(false);
            setCollections(result);
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            console.log("error", error);
            setLoading(false);
            setError(true);
          }
        );
    }

    loadCollections();
  }, [address]);

  return (
    <div className="flex flex-col overflow-hidden p-2">
      {collections ? (
        <div className="flex items-center bg-indigo-200 dark:bg-indigo-900 p-2 font-bold rounded-t-lg">
          <h2>Collections</h2>
        </div>
      ) : (
        ""
      )}

      <div className="overflow-y-auto overflow-x-hidden bg-white dark:bg-black">
        {loading ? <p>Loading...</p> : ""}
        <div className="w-full">
          {collections
            ? collections.map((c, index) => (
                <div
                  key={index}
                  className={`w-40 p-2 border-b ${
                    selectedCollection == c["slug"] ? "" : ""
                  } `}
                >
                  <img
                    className="w-30 h-30 mx-auto rounded"
                    onClick={(event) => updateSelectedCollection(c["slug"])}
                    key={index + c["slug"]}
                    src={c["image_url"]}
                    alt=""
                  />
                  <h2 className="px-2 block text-center text-sm max-h-10 whitespace-nowrap overflow-hidden text-ellipsis">
                    {c["name"]}
                  </h2>
                </div>
              ))
            : {}}
        </div>
      </div>
    </div>
  );
};

export default CollectionList;
