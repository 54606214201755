import React, { useState } from "react";
import SelectedNft from "../components/SelectedNft";
// import './ExploreContainer.css';

interface ContainerProps {
    hash: string;
    nfts: any[];
    removeNftFromSelected: any;
    moveNftFromSelected: any;
    clearSelected: any;
}

const SelectedNfts: React.FC<ContainerProps> = ({ hash, nfts, removeNftFromSelected, moveNftFromSelected, clearSelected }) => {
    return (
    <div className="h-46 pt-2 wp-full">
        <div>          
        {nfts && nfts.length ? 
        <div className="">
          <div className="flex items center w-full bg-indigo-200 dark:bg-indigo-900 p-2 font-bold rounded-t-lg">
            <h2 className="">Selected Nfts</h2>
            <button onClick={(event) => clearSelected()} className="ml-auto text-sm">Remove all</button>
          </div>
          <div className="w-100 flex overflow-auto border-b bg-border-100 dark:bg-border-800 dark:bg-black">
        {nfts
          ? nfts.map((n, index) => (
              <SelectedNft key={index} index={index} nft={n} total={nfts.length} removeNftFromSelected={removeNftFromSelected} moveNftFromSelected={moveNftFromSelected} />
            ))
          : {}}
      </div>
      </div>
      : '' }

        </div>
    </div>
  );
};

export default SelectedNfts;
