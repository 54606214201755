import React, { useState, useEffect } from "react";
// import './ExploreContainer.css';
import gifshot from "gifshot";

interface ContainerProps {
  hash: string;
  nfts: any[];
}

const SelectedNfts: React.FC<ContainerProps> = ({ hash, nfts }) => {
  const initialPlaceholder = "/assets/preview-placeholder.png";
  const initialInterval = "0.3";

  const [interval, setInterval] = useState(initialInterval);
  const [image, setImage] = useState(initialPlaceholder);
  const [previewing, setPreviewing] = useState(false);

  useEffect(() => {
    setImage(initialPlaceholder);
  }, [nfts]);

  function stopGif() {
    setPreviewing(false);
    setImage(initialPlaceholder);
  }

  function downloadGif() {
    // var canvas = document.createElement('canvas');
    // var gh = canvas.toDataURL('png');

    var a = document.createElement("a");
    a.href = image;
    a.download = "nifty-reel.gif";

    a.click();
  }

  function addBackgroundColor(imageURI: string, backgroundColor: string) {
    var image = new Image();
    image.src = imageURI;

    var canvas = document.createElement("canvas");
    var ctx: any = canvas.getContext("2d");

    canvas.width = 500;
    canvas.height = 500;

    // Add background color
    // ctx.fillStyle = backgroundColor;
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    ctx.drawImage(image, 0, 0);

    return canvas.toDataURL();
  }

  function createGif() {
    const images = nfts.map((nft) => nft["image_url"]);
    // images.push('/assets/watermark.png');

    const watermark=document.createElement('img');
    watermark.src='/assets/watermark.png';

    gifshot.createGIF(
      {
        images: images,
        interval: interval,
        gifWidth: 1000,
        gifHeight: 1000,
        waterMark: watermark,
        waterMarkHeight: 1000,
        waterMarkWidth: 1000,
        waterMarkXCoordinate: 0,
        waterMarkYCoordinate: 0,
      },
      function (obj: any) {
        if (!obj.error) {
          var image = obj.image,
            animatedImage = document.createElement("img");
          animatedImage.src = image;
          setImage(image);
          setPreviewing(true);
        } else {
          // console.log("error", obj.error);
        }
      }
    );
  }

  return (
    <div className="overflow-auto mt-6">
      {nfts && nfts.length ? (
        <div className="flex items-center mt-2">
          <h2 className="text-indigo-800 dark:text-white text-xl font-bold">Preview</h2>

          {nfts && nfts.length > 0 && !previewing ? (
            <button onClick={createGif} className="ml-2 text-indigo-800 dark:text-white">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          ) : (
            ""
          )}
          {previewing ? (
            <button onClick={stopGif} className="ml-2 text-indigo-800 dark:text-white">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zM8 7a1 1 0 00-1 1v4a1 1 0 001 1h4a1 1 0 001-1V8a1 1 0 00-1-1H8z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          ) : (
            ""
          )}
          {previewing ? (
            <button onClick={downloadGif} className="flex items-center ml-auto border rounded-lg px-3 py-2 text-indigo-800 dark:text-white font-bold">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 mr-1"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
              Download 
            </button>
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}
      <div className="flex">
        <div className="block mt-2" style={{ width: "500px", height: "500px" }}>
          {nfts && nfts.length && image ? (
            <img width="500" height="500" src={image} alt="" />
          ) : (
            ""
          )}
        </div>

        <div className="flex items-center ml-2">
          <div className="flex flex-col">
            <div></div>

            {/* {nfts && nfts.length ? (
              <input
                type="number"
                step="0.1"
                name="interval"
                value={interval}
                onChange={(event) => setInterval(event.target.value)}
              />
            ) : (
              ""
            )} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectedNfts;
