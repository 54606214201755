import { AnyNsRecord } from "dns";
import React, { useState } from "react";
// import './ExploreContainer.css';

interface ContainerProps {
  index: number;
  nft: any[];
  total: number;
  removeNftFromSelected: any;
  moveNftFromSelected: any;
}

const SelectedNft: React.FC<ContainerProps> = ({
  index,
  nft,
  total,
  removeNftFromSelected,
  moveNftFromSelected,
}) => {
  return (
    <div className="group flex-shrink-0">
      <div className="relative">
        <button
          onClick={(event) => removeNftFromSelected(index)}
          className="bg-white p-1 mt-1 mr-1 rounded-full absolute top-0 right-0 hidden group-hover:block text-indigo-800"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
              clipRule="evenodd"
            />
          </svg>
        </button>

        {index != 0 ? (
          <button
            className="bg-white p-1 rounded-full absolute bottom-0 left-0 hidden group-hover:block mb-1 ml-1 text-indigo-800"
            onClick={(event) => moveNftFromSelected(index, index - 1)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm.707-10.293a1 1 0 00-1.414-1.414l-3 3a1 1 0 000 1.414l3 3a1 1 0 001.414-1.414L9.414 11H13a1 1 0 100-2H9.414l1.293-1.293z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        ) : (
          ""
        )}

        {index != total-1 ? (
          <button
            className="bg-white p-1 rounded-full absolute bottom-0 right-0 hidden group-hover:block mb-1 mr-1 text-indigo-800"
            onClick={(event) => moveNftFromSelected(index, index + 1)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-8.707l-3-3a1 1 0 00-1.414 1.414L10.586 9H7a1 1 0 100 2h3.586l-1.293 1.293a1 1 0 101.414 1.414l3-3a1 1 0 000-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        ) : (
          ""
        )}

        <img className="w-40 h-40" src={nft["image_url"]} alt="" />
      </div>
    </div>
  );
};

export default SelectedNft;
