import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useParams } from "react-router";
import React, { useEffect, useState } from "react";
import NftList from "../components/NftList";
import CollectionList from "../components/CollectionList";
import SelectedNfts from "../components/SelectedNfts";
import Reel from "../components/Reel";

import { ethers } from "ethers";
import ReactGA from "react-ga4";
// import './Builder.css';

ReactGA.initialize([
  {
    trackingId: "G-E5D1QRK0B5",
  },
]);

const Builder: React.FC = () => {
  // 0x1890B66FD4DcCeE4F78672b12ebf540f2fe54411
  const [accountInput, setAccountInput] = useState("");
  const [address, setAddress] = useState("");
  const [collection, setCollection] = useState("");
  const [selectedNftsHash, setSelectedNftsHash] = useState("");
  const [selectedNfts, setSelectedNfts] = useState<any[]>([]);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [showExamples, setShowExamples] = useState(false);

  useEffect(() => {
    // ReactGA.pageview(window.location.pathname);
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  function sendGoogleEvent(
    category: string,
    action: string,
    label: string | undefined = undefined,
    value: number | undefined = undefined,
    nonInteraction: boolean | undefined = undefined,
    transport: "beacon" | "xhr" | "image" = "xhr"
  ) {
    // console.log('send ga event');
    ReactGA.event({
      category: category,
      action: action,
      // label: label, // optional
      // value: value, // optional, must be a number
      // nonInteraction: nonInteraction, // optional, true/false
      // transport: transport, // optional, beacon/xhr/image
    });
  }

  function toggleShowExamples() {
    setShowExamples(!showExamples);
  }

  function clearAddress() {
    setAccountInput("");
    setAddress("");
  }

  function restart() {
    setAccountInput("");
    setAddress("");
  }

  async function submitAccount() {
    const provider = new ethers.providers.JsonRpcProvider(
      "https://mainnet.infura.io/v3/25a9434c9f99499c85f02421c8b42aa5"
    );

    try {
      ethers.utils.getAddress(accountInput);
      // console.log('resolvedAddress', resolvedAddress);
      setAddress(accountInput);
      setErrorMessage(null);
      setError(false);

      sendGoogleEvent("wallet", "set");
    } catch (e) {
      const resolvedAddress = await provider.resolveName(accountInput);

      if (resolvedAddress) {
        setAddress(resolvedAddress);
        setErrorMessage(null);
        setError(false);
        sendGoogleEvent("wallet", "set");
      } else {
        setErrorMessage("No wallet found with this address");
        setError(true);
        sendGoogleEvent("wallet", "error");
      }
    }
  }

  function moveNftFromSelected(index: number, newPos: number) {
    // console.log('moveNftFromSelected: ', index, newPos);
    var list: any[] = selectedNfts;
    [list[index], list[newPos]] = [list[newPos], list[index]];
    const hashString = list
      .map(function (elem) {
        return elem.id;
      })
      .join(",");
    setSelectedNfts(list);
    setSelectedNftsHash(hashString);
  }

  function clearSelected(index: number) {
    setSelectedNfts([]);
    setSelectedNftsHash('');
  }

  function removeNftFromSelected(index: number) {
    // console.log('remove: ' + index, selectedNftsHash);
    var list: any[] = selectedNfts;
    list.splice(index, 1);
    // console.log('before', list);

    // let newState = [];
    // for(let i = 0; i < selectedNfts.length; i++){
    //   if(index !== i){
    //     newState.push(selectedNfts[i])
    //   }
    // }

    // console.log('after', list);
    const hashString = list
      .map(function (elem) {
        return elem.id;
      })
      .join(",");
    setSelectedNfts(list);
    setSelectedNftsHash(hashString);
  }

  function addNftToSelected(nft: any[]) {
    var list: any[] = selectedNfts;
    list.push(nft);
    const hashString = list
      .map(function (elem) {
        return elem.id;
      })
      .join(",");
    // console.log('added', list);
    setSelectedNfts(list);
    setSelectedNftsHash(hashString);
  }

  function reOrderSelectedNft(index: number, toIndex: number) {}

  // function hashCode(s) {
  //   var h = 0, l = s.length, i = 0;
  //   if ( l > 0 )
  //     while (i < l)
  //       h = (h << 5) - h + s.charCodeAt(i++) | 0;
  //   return h;
  // };

  return (
    <IonPage>
      <IonContent className="overflow-hidden" fullscreen>
        {accountInput && address ? (
          <div className="h-screen flex flex-col overflow-hidden">
            <header className="flex flex-shrink-0">
              <div className="w-64 flex-shrink-0 px-4 py-3 bg-white dark:bg-black">
                <button className="w-full flex items-center">
                  <img className="h-6" src="/assets/logo-name.svg" alt="" />
                </button>
              </div>
              <div className="flex-1 flex items-center px-6 justify-between bg-white dark:bg-black">
                <nav className="flex items-center">
                  <div>
                    Wallet:
                    <span className="ml-1">{accountInput}</span>
                  </div>
                  <div className="ml-1">
                    <button
                      className="ml-2 inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-sm font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      onClick={restart}
                    >
                      New wallet
                    </button>
                  </div>
                </nav>
                <div className="ml-auto">
                  Built by{" "}
                  <a target="_blank" href="https://twitter.com/thirdprojects">
                    @thirdprojects
                  </a>
                </div>
              </div>
            </header>
            <div className="flex flex-1 overflow-hidden border-t bg-white dark:bg-gray-600">
              <CollectionList address={address} setCollection={setCollection} />
              <NftList
                address={address}
                collection={collection}
                addNftToSelected={addNftToSelected}
              />
              <div className="flex-1 flex w-full ml-2 bg-white dark:bg-gray-600">
                <div className="flex flex-col w-full px-2">
                  <SelectedNfts
                    hash={selectedNftsHash}
                    nfts={selectedNfts}
                    removeNftFromSelected={removeNftFromSelected}
                    moveNftFromSelected={moveNftFromSelected}
                    clearSelected={clearSelected}
                  />
                  <Reel hash={selectedNftsHash} nfts={selectedNfts} />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="h-full flex items-center justify-center">

            <div className="container mx-auto max-w-xl">
              <div className="flex flex-col">
                <h1 className="flex justify-center">
                  <img className="h-8" src="/assets/logo-name.svg" alt="" />
                </h1>
                <div className="flex justify-center py-10 ">
                  <img className="h-20" src="/assets/logo-mark.svg" alt="" />
                </div>
                
                <div className="">
                  <p className="text">
                    Easily create an animated video reel of your favorite NFTs
                  </p>
                </div>

                <div className="block md:hidden mt-6">
                  Sorry Nifty Reel does not work on mobile devices.<br />Please try from your laptop/computer.
                </div>

                <div className="hidden md:block">
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mt-6">
                    Your Wallet Address
                  </label>
                  <div className="mt-1 flex">
                    <input
                      type="text"
                      name="address"
                      id="address"
                      value={accountInput}
                      className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md text-gray-700"
                      placeholder="Enter an ETH address or ENS name"
                      onChange={(event) => setAccountInput(event.target.value)}
                    />
                    <button
                      onClick={submitAccount}
                      className="ml-2 whitespace-nowrap inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-indigo-700 hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      Continue
                    </button>
                  </div>
                  {error ? (
                    <div>
                      <span className="text-red-500">{errorMessage}</span>
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div className="mt-6">
                    <button onClick={(event) => toggleShowExamples() } className="text-gray-500">
                      See examples
                    </button>
                    { showExamples ? 
                      <div className="grid grid-cols-3 gap-6 mt-6">
                        <img className="rounded" src="/assets/sample-1.gif" alt="" />
                        <img className="rounded" src="/assets/sample-2.gif" alt="" />
                        <img className="rounded" src="/assets/sample-3.gif" alt="" />
                      </div>
                    : null }
                  </div>                
              </div>
            </div>
          </div>
        )}
      </IonContent>
    </IonPage>
  );
};

export default Builder;
